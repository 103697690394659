// import { useState } from "react";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { backendService } from "../services/backend";
import styles from "../components/FormPage/FormPage.module.css";
import BtnWide from "../components/BtnWide";
import { isValidEmail } from "../functions/isValidEmail";

export default function Form() {
  const params = useParams();
  const [dbData, setDbData] = useState({});
  const [dbHooks, setDbHooks] = useState([]);
  const [inputData, setInputData] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);

  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [isFormError, setIsFormError] = useState(false);

  useEffect(() => {
    if (params.id && !dbData.id) {
      (async () => {
        await backendService
          .getDocument("forms", params.id)
          .then((doc) => setDbData({ id: doc.id, ...doc.data() }))
          .then(() => setLoading(false));
      })();
    }
  }, [params.id, dbData]);

  useEffect(() => {
    if (dbData.hookIds && dbData.hookIds.length > 0) {
      (async () => {
        let _dbData = [];
        await backendService
          .getDocumentsFromCollectionWhere("hooks", "userId", dbData.userId)
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              _dbData.push({ id: doc.id, ...doc.data() });
            });
          })
          .then((doc) => setDbHooks(_dbData))
          .then(() => setLoading(false));
      })();
    }
  }, [dbData]);

  const handleChange = (e, field) => {
    setInputData(Object.assign({}, inputData, { [field]: e.target.value }));
  };

  const handleBooleanChange = (e, field) => {
    setInputData(Object.assign({}, inputData, { [field]: e.target.checked }));
  };

  const handleCheckboxChange = (field, option) => {
    let _arr = [];
    if (inputData[field]) {
      _arr.push(...inputData[field]);
    }
    if (_arr.includes(option.value)) {
      _arr = _arr.filter((item) => item !== option.value);
    } else {
      _arr.push(option.value);
    }

    if (_arr.length === 0) _arr = ""; // if user selects, then deselects, setting to empty string helps validator catch it
    setInputData(Object.assign({}, inputData, { [field]: _arr }));
  };

  const prepareDataForSubmission = () => {
    let finish = true;
    let _dataObj = {};
    Object.keys(dbData.data).forEach((key) => (_dataObj[key] = "")); // create empty values in order to validate them

    Object.keys(inputData).forEach((key) => (_dataObj[key] = inputData[key])); // replace empty values with completed fields. This leaves uncompleted fields empty so that they can be validated

    Object.keys(dbData.data).forEach((key) => {
      if (_dataObj[key] === "" && dbData.data[key].required) {
        finish = false;
        setIsFormError(true);
        // setFormErrorMessage(dbData.data[key].index + " is required");
        return setFormErrorMessage("Please complete all required fields");
      }

      // this is validating email fields
      if (dbData.data[key].type === "email") {
        if (!isValidEmail(_dataObj[key])) {
          setIsFormError(true);
          finish = false;
          return setFormErrorMessage("Invalid email");
        }
      }
    });

    if (finish === true) {
      let obj = {
        formId: params.id,
        createdAt: new Date().getTime(),
        data: _dataObj,
      };
      return obj;
    }
    return false;
  };

  const handleHookSubmissions = async (responseId) => {
    try {
      for await (let hook of dbHooks) {
        let {
          // formId,
          formKeyToCollect,
          formKeyToInspect,
          formKeyToInspectCondition,
          collectionId,
        } = hook.data;
        if (inputData[formKeyToInspect] && formKeyToInspectCondition) {
          // if a condition has to be met in order for data to be copied to a collection
          if (inputData[formKeyToInspect].toString() === formKeyToInspectCondition.toString()) {
            if (responseId) {
              await backendService.updateValueInDocument(
                "collections",
                collectionId,
                `data.${responseId}`,
                inputData[formKeyToCollect]
              );
            }
          }
        } else if (inputData[formKeyToInspect] && !formKeyToInspectCondition) {
          // no condition was given to hook, we just want to copy data to a collection
          if (responseId) {
            await backendService.updateValueInDocument(
              "collections",
              collectionId,
              `data.${responseId}`,
              inputData[formKeyToCollect]
            );
          }
        }
      }
    } catch (error) {
      await backendService.addDocumentToCollection("logs", error);
      setFormSubmitted(true);
    } finally {
      setFormSubmitted(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let _formData = prepareDataForSubmission();
    try {
      if (_formData) {
        let response = await backendService.addDocumentToCollection("submissions", _formData);
        if (response) {
          if (dbHooks.length > 0) {
            handleHookSubmissions(response);
          } else {
            setFormSubmitted(true);
          }
        }
      }
    } catch (error) {
      await backendService.addDocumentToCollection("logs", error);
      setIsFormError(true);
      setFormErrorMessage("There was an error submitting your form. Please try again later");
    }
  };

  if (formSubmitted) {
    return (
      <div className="page-container">
        <h1>{dbData.onSubmitMessage ? dbData.onSubmitMessage : "Form submitted."}</h1>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="page-container">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <div className="page-container">
      <h1>{dbData.title ? dbData.title : "Form"}</h1>
      {dbData.id ? (
        <div className={styles.container}>
          <form>
            {Object.keys(dbData.data).map((_d) => (
              <Fragment key={_d}>
                <label htmlFor={_d} className={styles.formLabel}>
                  {dbData.data[_d].required ? `${dbData.data[_d].label}*` : dbData.data[_d].label}
                </label>

                {dbData.data[_d].variant === "input" && (
                  <input
                    name={_d}
                    className={styles.formField}
                    type={dbData.data[_d].type}
                    onChange={(e) => handleChange(e, _d)}
                    required={dbData.data[_d].required}
                  />
                )}
                {dbData.data[_d].variant === "textarea" && (
                  <textarea
                    name={_d}
                    className={styles.formFieldTextArea}
                    onChange={(e) => handleChange(e, _d)}
                    required={dbData.data[_d].required}></textarea>
                )}
                {dbData.data[_d].variant === "select" && (
                  <div className="options-container">
                    <select
                      name={_d}
                      className={styles.dropdown}
                      required={dbData.data[_d].required}
                      onChange={(e) => handleChange(e, _d)}
                      // TODO improve this so that the default value and disabled option things isn't required
                      defaultValue="Select an option">
                      <option disabled>Select an option</option>
                      {dbData.data[_d].options
                        .sort((a, b) => (a.index > b.index ? 1 : -1))
                        .map((option) => (
                          <option key={option.index} type="text">
                            {option.value}
                          </option>
                        ))}
                    </select>
                  </div>
                )}

                {dbData.data[_d].variant === "checkbox" &&
                  dbData.data[_d].options
                    .sort((a, b) => (a.index > b.index ? 1 : -1))
                    .map((option) => (
                      <div className={styles.formFieldCheckboxContainer} key={option.index}>
                        <input
                          name={`${_d}_${option.index}`}
                          className={styles.formFieldCheckbox}
                          type="checkbox"
                          htmlFor={option.value}
                          required={dbData.data[_d].required}
                          onChange={() => handleCheckboxChange(_d, option)}
                        />
                        <label className={styles.formFieldCheckboxLabel}>{option.value}</label>
                      </div>
                    ))}

                {dbData.data[_d].variant === "boolean" && (
                  <input
                    name={_d}
                    type="checkbox"
                    className={styles.formFieldCheckbox}
                    onChange={(e) => handleBooleanChange(e, _d)}
                    required={dbData.data[_d].required}
                  />
                )}
              </Fragment>
            ))}
            {isFormError && <p>{formErrorMessage}</p>}
            <BtnWide
              func={(e) => handleSubmit(e)}
              name={dbData.submitButton ? dbData.submitButton : "Submit form"}
            />
          </form>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}
