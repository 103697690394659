import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import BtnSmall from "../components/BtnSmall";
import BtnWide from "../components/BtnWide";
import { backendService } from "../services/backend";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState({});

  const login = (e) => {
    e.preventDefault();
    backendService.loginWithEmailAndPassword(email, password);
  };

  useEffect(() => {
    backendService.attachAuthStateChangeHandler((user) => {
      if (user) {
        setUser(user);
        setAuthenticated(true);
      }
    });
  }, [user, authenticated]);

  if (authenticated) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <div className="page-container">
      <h1>Login</h1>
      <form className="flex-column">
        <label>Email</label>

        <input
          className="form-field"
          type="email"
          name="email"
          autoComplete="username"
          onChange={(e) => setEmail(e.target.value)}
        />

        <label>Password</label>

        <input
          className="form-field"
          type="password"
          name="password"
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
        />

        <BtnWide name="Login" func={(e) => login(e)} />
      </form>
    </div>
  );
}
