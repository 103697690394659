import { Fragment, useEffect, useState } from "react";
import { backendService } from "../services/backend";
import styles from "../components/FormPage/FormPage.module.css";
import BtnWide from "../components/BtnWide";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import logo from "../assets/logo.png";
export default function Index() {
  document.body.style = "background: white;";

  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState();

  useEffect(() => {
    backendService.attachAuthStateChangeHandler((user) => {
      if (user) {
        setUser(user);
        setAuthenticated(true);
      }
    });
  }, [user, authenticated]);

  return (
    <>
      <div className="navbar">
        <img src={logo} alt="Formula logo" />
        {authenticated ? (
          <Link to="dashboard" className="btn-blue">
            Dashboard
          </Link>
        ) : (
          <Link to="login" className="navbar-link">
            Sign in
          </Link>
        )}
      </div>
      <div className="page-container">
        <h1 className="jumbo-header">
          Formula <span className="text-grey">is the smart way to collect and organise data.</span>
        </h1>
        <PageForm />
      </div>
    </>
  );
}

function PageForm() {
  const [dbData, setDbData] = useState({});
  const [inputData, setInputData] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const param = "0VZ9MbJNJIQwEkmK58aU";

  const [loading, setLoading] = useState(true);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [isFormError, setIsFormError] = useState(false);

  useEffect(() => {
    if (!dbData.id) {
      (async () => {
        await backendService
          .getDocument("forms", param)
          .then((doc) => setDbData({ id: doc.id, ...doc.data() }))
          .then(() => setLoading(false));
      })();
    }
  }, [dbData.id]);

  function emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  const handleChange = (e, field) => {
    setInputData(Object.assign({}, inputData, { [field]: e.target.value }));
  };

  const prepareDataForSubmission = () => {
    if (emailIsValid(inputData[101])) {
      setIsFormError(false);
      setFormErrorMessage("");
      let obj = {
        formId: param,
        createdAt: new Date().getTime(),
        data: inputData,
      };
      return obj;
    }
    setIsFormError(true);
    setFormErrorMessage("Invalid email");
    return false;
  };

  const handleSubmit = async () => {
    try {
      let _formData = prepareDataForSubmission();
      let response = await backendService.addDocumentToCollection("submissions", _formData);
      if (response) {
        setFormSubmitted(true);
      }
    } catch (error) {
      await backendService.addDocumentToCollection("logs", error);
    }
  };

  if (formSubmitted) {
    return (
      <div className="homepage-form-container">
        <div className="page-container">
          <h1>{dbData.onSubmitMessage ? dbData.onSubmitMessage : "Form submitted."}</h1>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="homepage-form-container">
        <Loading />
      </div>
    );
  }

  return (
    <div className="homepage-form-container">
      <h3 className="homepage-form-title">{dbData.title ? dbData.title : "Form"}</h3>
      {dbData.id ? (
        <div className={styles.container}>
          {Object.keys(dbData.data).map((_d) => (
            <Fragment key={_d}>
              <label htmlFor={_d} className="homepage-label">
                {dbData.data[_d].label}
              </label>

              {dbData.data[_d].variant === "input" && (
                <input
                  name={_d}
                  className={styles.formField}
                  type={dbData.data[_d].type}
                  onChange={(e) => handleChange(e, _d)}
                  required={dbData.data[_d].required}
                />
              )}
              {dbData.data[_d].variant === "textarea" && (
                <textarea
                  name={_d}
                  className={styles.formFieldTextArea}
                  onChange={(e) => handleChange(e, _d)}
                  required={dbData.data[_d].required}></textarea>
              )}
              {dbData.data[_d].variant === "select" && (
                <div className="options-container">
                  <select
                    name={_d}
                    className={styles.dropdown}
                    required={dbData.data[_d].required}
                    onChange={(e) => handleChange(e, _d)}
                    // TODO improve this so that the default value and disabled option things isn't required
                    defaultValue="Select an option">
                    <option disabled>Select an option</option>
                    {dbData.data[_d].options
                      .sort((a, b) => (a.index > b.index ? 1 : -1))
                      .map((option) => (
                        <option key={option.index} type="text">
                          {option.value}
                        </option>
                      ))}
                  </select>
                </div>
              )}

              {dbData.data[_d].variant === "checkbox" &&
                dbData.data[_d].options
                  .sort((a, b) => (a.index > b.index ? 1 : -1))
                  .map((option) => (
                    <div className={styles.formFieldCheckboxContainer} key={option.index}>
                      <input
                        name={`${_d}_${option.index}`}
                        className={styles.formFieldCheckbox}
                        type="checkbox"
                        htmlFor={option.value}
                        required={dbData.data[_d].required}
                        onChange={(e) => handleChange(e, _d)}
                      />
                      <label>{option.value}</label>
                    </div>
                  ))}
            </Fragment>
          ))}
          <button className="homepage-form-btn" onClick={handleSubmit}>
            {dbData.submitButton ? dbData.submitButton : "Submit form"}
          </button>
          {isFormError && <p>{formErrorMessage}</p>}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}
