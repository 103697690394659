import styles from "./Dropdown.module.css";

export default function Dropdown(props) {
  return (
    <select onChange={props.func} className={styles.dropdown} defaultValue={props.default}>
      {props.options.map((option, index) => (
        <option key={index}>{option}</option>
      ))}
    </select>
  );
}
