import { useContext, useEffect, useState } from "react";
import Btn from "../../components/Btn";
import BtnWide from "../../components/BtnWide";
import { Redirect } from "react-router-dom";
import FormField from "../../components/FormField";
import { backendService } from "../../services/backend";
import { AuthContext } from "../../components/AuthProvider";
// import { backendService } from "../../services/backend";
import styles from "../../components/FormPage/FormPage.module.css";
import HorizontalLine from "../../components/HorizontalLine";
import FormFieldBtn from "../../components/FormFieldBtn";

export default function NewKey() {
  const [lastIndex, setLastIndex] = useState(100);
  const [formData, setFormData] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { isAuth } = useContext(AuthContext);
  const [selectedFormId, setSelectedFormId] = useState("");
  const [formURL, setFormURL] = useState("");
  const [dbData, setDbData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userKeysId, setUserKeysId] = useState("");

  useEffect(() => {
    let _data = [];
    (async () => {
      setIsLoading(true);
      let req = await backendService
        .getDocumentsFromCollectionWhere("keys", "userId", isAuth.uid)
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            _data.push(doc.id);
          });
        })
        .then(() => {
          setUserKeysId(..._data);
        })
        .then(() => {
          setIsLoading(false);
        });
    })();
  }, [isAuth.uid]);

  useEffect(() => {
    let _data = [];
    (async () => {
      setIsLoading(true);
      let req = await backendService
        .getDocumentsFromCollectionWhere("forms", "userId", isAuth.uid)
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            _data.push({ id: doc.id, ...doc.data() });
          });
        })
        .then(() => {
          setDbData(_data);
        })
        .then(() => {
          setIsLoading(false);
        });
    })();
  }, [isAuth.uid]);

  const prepareDataForSubmission = () => {
    let obj = {
      userId: isAuth.uid,
      // title: formTitle,
      // slug: formSlug
      //   ? formSlug.toLowerCase().replace(/\s+/g, "-")
      //   : formTitle.toLowerCase().replace(/\s+/g, "-"),
      createdAt: new Date().getTime(),
      data: {},
    };
    return obj;
  };

  const handleSubmit = async () => {
    // if (formTitle.length > 1) {
    try {
      let _formData = {
        formId: selectedFormId,
        url: formURL,
      };

      let response = await backendService.addDocumentToCollection("api", _formData);
      if (response) {
        await backendService
          .pushValueToArrayInDocument("keys", userKeysId, "keyIds", response)
          .then(() => setFormSubmitted(true));
      }
    } catch (error) {
      await backendService.addDocumentToCollection("logs", error);
    }
    // }
  };

  if (formSubmitted) {
    return <Redirect to="/dashboard/keys" />;
  }
  return (
    <div className="page-container">
      <h1>Create new key</h1>

      <div>
        <h4>Select form</h4>
        {dbData.length > 0 &&
          dbData.map((form) => (
            <FormFieldBtn
              func={() => setSelectedFormId(form.id)}
              name={form.slug}
              key={form.id}
              active={selectedFormId === form.id}
            />
          ))}
      </div>

      <div className="flex-column">
        <label>Full URL where you plan to use the form (must be HTTPS)</label>
        <input
          placeholder="https://example.com"
          className={styles.formFieldStretch}
          type="text"
          onChange={(e) => setFormURL(e.target.value)}
        />
      </div>

      <BtnWide func={handleSubmit} name="Generate key" />
    </div>
  );
}
