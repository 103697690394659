import { useState, useEffect, useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../components/AuthProvider";
import Btn from "../../components/Btn";
import BtnSmall from "../../components/BtnSmall";
import HorizontalLine from "../../components/HorizontalLine";
import Loading from "../../components/Loading";
import { backendService } from "../../services/backend";

export default function Dashboard() {
  const signOut = () => {
    backendService.signOut();
  };

  return (
    <div className="page-container">
      <div className="options-container">
        <h1>Dashboard</h1>
      </div>

      <Link to="/dashboard/forms">
        <h3>Forms</h3>
      </Link>
      <HorizontalLine />

      <Link to="/dashboard/collections">
        <h3>Collections</h3>
      </Link>
      <HorizontalLine />

      <Link to="/dashboard/hooks">
        <h3>Hooks</h3>
      </Link>
      <HorizontalLine />

      <Link to="/dashboard/keys">
        <h3>Keys</h3>
      </Link>
      <HorizontalLine />
      {/* <Link to="/dashboard/orbit">
        <h3>Orbit (Analytics)</h3>
      </Link>
      <HorizontalLine /> */}
    </div>
  );
}
