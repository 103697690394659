import { useState, useEffect, useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../components/AuthProvider";
import Btn from "../../components/Btn";
import HorizontalLine from "../../components/HorizontalLine";
import Loading from "../../components/Loading";
import { backendService } from "../../services/backend";

export default function Keys() {
  const { isAuth } = useContext(AuthContext);
  let [dbData, setDbData] = useState("");
  let [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let _data = [];
    (async () => {
      setIsLoading(true);
      let req = await backendService
        .getDocumentsFromCollectionWhere("keys", "userId", isAuth.uid)
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            _data.push({ id: doc.id, ...doc.data() });
          });
        })
        .then(() => {
          setDbData(..._data);
        })
        .then(() => {
          setIsLoading(false);
        });
    })();
  }, [isAuth.uid]);

  // console.log(dbData);

  return (
    <div className="page-container">
      <div className="options-container">
        <h1>Keys</h1>
        <Link to="/dashboard">
          <h3>Dashboard</h3>
        </Link>
      </div>
      <Link to="/dashboard/key/new" className="options-container">
        <h3>Create new key</h3>
        <Btn green />
      </Link>
      <HorizontalLine />
      <h3>Existing keys</h3>
      {isLoading ? (
        <Loading />
      ) : (
        dbData &&
        dbData.keyIds.map((data) => (
          <Fragment key={data}>
            <ExistingFormLink data={data} />
            <HorizontalLine />
          </Fragment>
        ))
      )}
    </div>
  );
}

function ExistingFormLink({ data }) {
  return (
    <div>
      <h4>ID: {data}</h4>

      <Link to={`/dashboard/k/${data}`} className="options-container">
        <p>Edit key</p>
        <Btn orange />
      </Link>
    </div>
  );
}
