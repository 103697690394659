import { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { backendService } from "../../services/backend";
import styles from "../../components/FormPage/FormPage.module.css";
import BtnWide from "../../components/BtnWide";
import Btn from "../../components/Btn";
import FormField from "../../components/FormField";
import HorizontalLine from "../../components/HorizontalLine";
import Loading from "../../components/Loading";

export default function FormEdit() {
  const params = useParams();
  const [dbData, setDbData] = useState({});
  const [lastIndex, setLastIndex] = useState(100);
  const [formData, setFormData] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSlug, setFormSlug] = useState("");
  const [formTitle, setFormTitle] = useState("");
  const [formSubmitMessage, setFormSubmitMessage] = useState("");
  const [submitButtonMessage, setSubmitButtonMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [isFormError, setIsFormError] = useState(false);

  useEffect(() => {
    if (params.id && !dbData.id) {
      setLoading(true);
      (async () => {
        await backendService
          .getDocument("forms", params.id)
          .then((doc) => setDbData({ id: doc.id, ...doc.data() }))
          .then(() => setLoading(false));
      })();
    }
  }, [params.id, dbData.id]);

  useEffect(() => {
    if (dbData.id) {
      let _dbData = [];
      Object.keys(dbData.data).forEach((data) => _dbData.push(dbData.data[data]));
      setFormData(_dbData);
      setFormTitle(dbData.title);
      setFormSlug(dbData.slug);
      setFormSubmitMessage(dbData.onSubmitMessage);
      setSubmitButtonMessage(dbData.submitButton);
      setLastIndex(_dbData[_dbData.length - 1].index);
    }
  }, [dbData]);

  const handleFormUpdate = (updatedData) => {
    const formDataIndex = formData.findIndex((element) => element.index === updatedData.index);
    let newArray = [...formData];
    newArray[formDataIndex] = {
      ...updatedData,
    };
    setFormData(newArray);
  };

  const addFormField = () => {
    let newLastIndex = lastIndex + 1;
    let newFormField = { index: newLastIndex, variant: "input", type: "text", label: "" };
    let newArray = [...formData, newFormField];
    setLastIndex(newLastIndex);
    setFormData(newArray);
  };

  const removeFormField = (index) => {
    setFormData(formData.filter((data) => data.index !== index));
  };

  const prepareDataForSubmission = () => {
    let obj = {
      userId: dbData.userId,
      title: formTitle,
      slug: dbData.slug,
      onSubmitMessage: formSubmitMessage,
      submitButton: submitButtonMessage,
      createdAt: dbData.createdAt,
      expiresAt: "",
      data: {},
    };
    formData.forEach((data) => (obj["data"][data.index] = data));
    return obj;
  };

  const handleSubmit = async () => {
    if (formData.length > 0) {
      try {
        let _formData = prepareDataForSubmission();
        await backendService
          .updateDocument("forms", params.id, _formData)
          .then(() => setFormSubmitted(true));
      } catch (error) {
        await backendService.addDocumentToCollection("logs", error);
        setIsFormError(true);
        setFormErrorMessage("There was an error updating your form. Please try again later");
      }
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (formSubmitted) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <div className="page-container">
      <div className="page-container-inner">
        <h1>Edit form</h1>

        <div className="flex-column">
          <label>Form title</label>
          <input
            className={styles.formFieldStretch}
            type="text"
            onChange={(e) => setFormTitle(e.target.value)}
            defaultValue={formTitle}
          />
        </div>

        <div className="flex-column">
          <label>Form Slug</label>
          <input className={styles.formFieldStretch} type="text" defaultValue={formSlug} disabled />
        </div>

        <div className="flex-column">
          <label>Submit button text</label>
          <input
            className={styles.formField}
            type="text"
            placeholder="Submit form"
            onChange={(e) => setSubmitButtonMessage(e.target.value)}
            defaultValue={submitButtonMessage}
          />
        </div>

        <div className="flex-column">
          <label>Form Submitted message</label>
          <input
            className={styles.formFieldStretch}
            type="text"
            placeholder="Form submitted."
            onChange={(e) => setFormSubmitMessage(e.target.value)}
            defaultValue={formSubmitMessage}
          />
        </div>

        <HorizontalLine />

        {formData.map((formFieldData) => (
          <FormField
            key={formFieldData.index}
            data={formFieldData}
            setData={handleFormUpdate}
            index={formFieldData.index}
            remove={removeFormField}
          />
        ))}

        <div className="options-container">
          <h3>Add form field</h3>
          <Btn green func={addFormField} />
        </div>
      </div>
      <BtnWide func={handleSubmit} name="Save updates" />
      {isFormError && <p>{formErrorMessage}</p>}
    </div>
  );
}
