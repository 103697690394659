import { useContext, useState } from "react";
import Btn from "../../components/Btn";
import BtnWide from "../../components/BtnWide";
import { Redirect } from "react-router-dom";
import FormField from "../../components/FormField";
import { backendService } from "../../services/backend";
import { AuthContext } from "../../components/AuthProvider";
// import { backendService } from "../../services/backend";
import styles from "../../components/FormPage/FormPage.module.css";
import HorizontalLine from "../../components/HorizontalLine";

export default function NewForm() {
  const [lastIndex, setLastIndex] = useState(100);
  const [formData, setFormData] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { isAuth } = useContext(AuthContext);
  const [formSlug, setFormSlug] = useState("");
  const [formTitle, setFormTitle] = useState("");
  const [formSubmitMessage, setFormSubmitMessage] = useState("");
  const [submitButtonMessage, setSubmitButtonMessage] = useState("");

  const handleFormUpdate = (updatedData) => {
    const formDataIndex = formData.findIndex((element) => element.index === updatedData.index);
    let newArray = [...formData];
    newArray[formDataIndex] = {
      ...updatedData,
    };
    setFormData(newArray);
  };

  const addFormField = () => {
    let newLastIndex = lastIndex + 1;
    let newFormField = { index: newLastIndex, variant: "input", type: "text", label: "" };
    let newArray = [...formData, newFormField];
    setLastIndex(newLastIndex);
    setFormData(newArray);
  };

  const removeFormField = (index) => {
    setFormData(formData.filter((data) => data.index !== index));
  };

  const prepareDataForSubmission = () => {
    let obj = {
      userId: isAuth.uid,
      title: formTitle,
      slug: formSlug
        ? formSlug.toLowerCase().replace(/\s+/g, "-")
        : formTitle.toLowerCase().replace(/\s+/g, "-"),
      onSubmitMessage: formSubmitMessage,
      submitButton: submitButtonMessage,
      createdAt: new Date().getTime(),
      expiresAt: "",
      data: {},
      hookIds: [],
    };
    formData.forEach((data) => (obj["data"][data.index] = data));
    return obj;
  };

  const handleSubmit = async () => {
    if (formData.length > 0) {
      try {
        let _formData = prepareDataForSubmission();
        let response = await backendService.addDocumentToCollection("forms", _formData);
        if (response) {
          setFormSubmitted(true);
        }
      } catch (error) {
        await backendService.addDocumentToCollection("logs", error);
      }
    }
  };

  if (formSubmitted) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <div className="page-container">
      <div className="page-container-inner">
        <h1>Create new form</h1>

        <div className="flex-column">
          <label>Form title</label>
          <input
            className={styles.formFieldStretch}
            type="text"
            onChange={(e) => setFormTitle(e.target.value)}
          />
        </div>

        <div className="flex-column">
          <label>Form Slug</label>
          <input
            placeholder="An easy way to refer to your form - users will not see this"
            className={styles.formFieldStretch}
            type="text"
            onChange={(e) => setFormSlug(e.target.value)}
          />
        </div>

        <div className="flex-column">
          <label>Submit button text</label>
          <input
            className={styles.formField}
            type="text"
            placeholder="Submit form"
            onChange={(e) => setSubmitButtonMessage(e.target.value)}
          />
        </div>

        <div className="flex-column">
          <label>Form Submitted message</label>
          <input
            className={styles.formFieldStretch}
            type="text"
            placeholder="Form submitted."
            onChange={(e) => setFormSubmitMessage(e.target.value)}
          />
        </div>

        <HorizontalLine />

        {formData.map((formFieldData) => (
          <FormField
            key={formFieldData.index}
            data={formFieldData}
            setData={handleFormUpdate}
            index={formFieldData.index}
            remove={removeFormField}
          />
        ))}

        <div className="options-container">
          <h3>Add form field</h3>
          <Btn green func={addFormField} />
        </div>
      </div>
      <BtnWide func={handleSubmit} name="Save form" />
    </div>
  );
}
