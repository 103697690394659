// import { useState } from "react";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BtnSmall from "../../components/BtnSmall";
import HorizontalLine from "../../components/HorizontalLine";
import { backendService } from "../../services/backend";

export default function CollectionData() {
  const params = useParams();
  // const [dbSubmissionData, setDbSubmissionData] = useState("");
  const [loading, setLoading] = useState(true);
  const [dbCollectionData, setDbCollectionData] = useState("");
  // const [formDataPreparedForUI, setFormDataPreparedForUI] = useState("");
  // const [submissionDataPreparedForUI, setSubmissionDataPreparedForUI] = useState("");

  useEffect(() => {
    if (params.id && !dbCollectionData) {
      (async () => {
        await backendService
          .getDocument("collections", params.id)
          .then((doc) => setDbCollectionData({ id: doc.id, ...doc.data() })) // possibly wrap this inside an array if it makes things easier
          .then(() => setLoading(false));
      })();
    }
  }, [params.id, dbCollectionData]);

  // console.log(dbCollectionData);
  // useEffect(() => {
  //   if (params.id && !dbSubmissionData) {
  //     (async () => {
  //       let _dbSubmissionData = [];
  //       await backendService
  //         .getDocumentsFromCollectionWhere("collections", "userId", )
  //         .then((querySnapshot) => {
  //           querySnapshot.forEach((doc) => {
  //             _dbSubmissionData.push({ id: doc.id, ...doc.data() });
  //           });
  //         })
  //         .then(() => setDbSubmissionData(_dbSubmissionData))
  //         .then(() => setLoading(false));
  //     })();
  //   }
  // }, [params.id, dbSubmissionData]);

  // useEffect(() => {
  //   if (dbSubmissionData.length && !submissionDataPreparedForUI) {
  //     let _dbSubmissionData = dbSubmissionData.map((_d) => ({
  //       createdAt: _d.createdAt,
  //       data: Object.entries(_d.data),
  //     }));
  //     setSubmissionDataPreparedForUI(_dbSubmissionData);
  //   }
  // }, [dbSubmissionData, submissionDataPreparedForUI]);

  // useEffect(() => {
  //   if (dbCollectionData.id && !formDataPreparedForUI) {
  //     let data = [
  //       {
  //         keys: Object.keys(dbCollectionData.data),
  //         labels: Object.values(dbCollectionData.data).map((_v) => _v.label),
  //       },
  //     ];
  //     let _a = data[0].keys.map((val, index) => ({
  //       key: val,
  //       label: data[0].labels[index],
  //     }));

  //     let _title = dbCollectionData.title;
  //     let _createdAt = dbCollectionData.createdAt;
  //     setFormDataPreparedForUI({ createdAt: _createdAt, data: _a, title: _title });
  //   }
  // }, [dbCollectionData, formDataPreparedForUI]);

  const downloadAsCSV = (data) => {
    // Building the CSV from the Data two-dimensional array
    // Each column is separated by ";" and new line "\n" for next row
    let csvContent = "";
    // data.forEach(function (infoArray, index) {
    let dataString = data.join(",");
    csvContent += dataString;
    // });

    // The download function takes a CSV string, the filename and mimeType as parameters
    // Scroll/look down at the bottom of this snippet to see how download is called
    let download = function (content, fileName, mimeType) {
      var a = document.createElement("a");
      mimeType = mimeType || "application/octet-stream";

      if (navigator.msSaveBlob) {
        // IE10
        navigator.msSaveBlob(
          new Blob([content], {
            type: mimeType,
          }),
          fileName
        );
      } else if (URL && "download" in a) {
        //html5 A[download]
        a.href = URL.createObjectURL(
          new Blob([content], {
            type: mimeType,
          })
        );
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        window.location.href = "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
      }
    };

    download(
      csvContent,
      `${dbCollectionData.slug ? dbCollectionData.slug : "download"}.csv`,
      "text/csv;encoding:utf-8"
    );
  };

  const downloadAsTxt = (data) => {
    // Building the CSV from the Data two-dimensional array
    // Each column is separated by ";" and new line "\n" for next row
    let _content = "";
    // data.forEach(function (infoArray, index) {
    let dataString = data.join("\r\n");
    _content += dataString;
    // });

    // The download function takes a CSV string, the filename and mimeType as parameters
    // Scroll/look down at the bottom of this snippet to see how download is called
    let download = (content, fileName, mimeType) => {
      var a = document.createElement("a");
      mimeType = mimeType || "application/octet-stream";

      if (navigator.msSaveBlob) {
        // IE10
        navigator.msSaveBlob(
          new Blob([content], {
            type: mimeType,
          }),
          fileName
        );
      } else if (URL && "download" in a) {
        //html5 A[download]
        a.href = URL.createObjectURL(
          new Blob([content], {
            type: mimeType,
          })
        );
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        window.location.href = "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
      }
    };

    download(
      _content,
      `${dbCollectionData.slug ? dbCollectionData.slug : "download"}.txt`,
      "text/plain;encoding:utf-8"
    );
  };

  if (loading) {
    return (
      <div className="page-container">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <div className="page-container">
      {dbCollectionData.title && <h1>{dbCollectionData.title}</h1>}
      <br />
      <h3>Data</h3>
      <BtnSmall
        func={() => downloadAsCSV(Object.values(dbCollectionData.data))}
        name="Download as CSV"
      />
      <BtnSmall
        func={() => downloadAsTxt(Object.values(dbCollectionData.data))}
        name="Download as TXT"
      />
      {dbCollectionData && <p>{Object.values(dbCollectionData.data).length} entries</p>}
      {dbCollectionData &&
        Object.values(dbCollectionData.data)
          // .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
          .map((_data, index) => (
            <Fragment key={`collectionData_${index}_${_data}`}>
              <p>{_data}</p>
              <HorizontalLine />
            </Fragment>
          ))}
    </div>
  );
}
