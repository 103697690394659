import { useState } from "react";
import Btn from "../Btn";
import Dropdown from "../Dropdown";
import FormFieldBtn from "../FormFieldBtn";
import HorizontalLine from "../HorizontalLine";
import { DEFAULT_OPTIONS, DEFAULT_OPTION_VARIANTS } from "./FormField.config";
import styles from "./FormField.module.css";

export default function FormField({ data, setData, index, remove }) {
  const [lastIndex, setLastIndex] = useState(100);
  const [options, setOptions] = useState([]);
  const handleDropdownSelection = (e) => {
    setData(Object.assign({}, data, { variant: e.target.value }));
    if (options.length === 0) {
      if (e.target.value === "select" || e.target.value === "checkbox") {
        addOption();
      }
    }
  };

  const handleDropdownTypeSelection = (e) => {
    setData(Object.assign({}, data, { type: e.target.value }));
  };

  const handleLabelChange = (e) => {
    setData(Object.assign({}, data, { label: e.target.value }));
  };

  const handleOptionChange = (e, index) => {
    let restOptions = options.filter((option) => option.index !== index);
    let optionToUpdate = options.filter((option) => option.index === index);
    optionToUpdate[0].value = e.target.value;
    let newOptions = [...restOptions, ...optionToUpdate];

    setData(Object.assign({}, data, { options: newOptions }));
  };

  const toggleSetActive = (settingValue) => {
    if (data[settingValue]) {
      setData(Object.assign({}, data, { [settingValue]: false }));
    } else {
      setData(Object.assign({}, data, { [settingValue]: true }));
    }
  };

  const addOption = () => {
    const newLastIndex = lastIndex + 1;
    let newOptions = [...options, { index: newLastIndex, value: "" }];
    setLastIndex(newLastIndex);
    setOptions(newOptions);
  };

  const removeOption = (index) => {
    let newOptions = options.filter((option) => option.index !== index);
    setOptions(newOptions);

    setData(() =>
      Object.assign({}, data, {
        options: newOptions,
      })
    );
  };

  return (
    <div className={styles.container}>
      <label>Input Type</label>
      <div className="options-container margin-bottom-20">
        <div>
          <Dropdown
            options={DEFAULT_OPTIONS}
            func={handleDropdownSelection}
            default={data.variant}
          />

          {DEFAULT_OPTION_VARIANTS[data.variant].type.length > 0 && (
            <Dropdown
              options={DEFAULT_OPTION_VARIANTS[data.variant].type}
              func={handleDropdownTypeSelection}
              default={data.type}
            />
          )}
        </div>
        <Btn red func={() => remove(index)} />
      </div>
      <label>Input Label</label>
      <input
        className={styles.formField}
        type="text"
        placeholder={`Enter ${data.variant} label`}
        onChange={handleLabelChange}
        defaultValue={data.label}
      />
      {data.variant === "select" && (
        <label>{data.variant.charAt(0).toUpperCase() + data.variant.slice(1)} Options</label>
      )}
      {data.variant === "checkbox" && (
        <label>{data.variant.charAt(0).toUpperCase() + data.variant.slice(1)} Options</label>
      )}
      {/* {data.variant === "input" && <input className={styles.formField} type={data.type} disabled />}
      {data.variant === "textarea" && (
        <textarea className={styles.formFieldTextArea} disabled></textarea>
      )} */}
      {data.variant === "checkbox" &&
        options
          .sort((a, b) => (a.index > b.index ? 1 : -1))
          .map((option, index) => (
            <div className="options-container" key={option.index}>
              <input
                className={styles.formField}
                type="text"
                placeholder="Enter checkbox option"
                onChange={(e) => handleOptionChange(e, option.index)}
                defaultValue={option.value}
              />
              {options.length === 1 ? (
                <Btn green func={addOption} />
              ) : index === options.length - 1 ? (
                <Btn green func={addOption} />
              ) : (
                <Btn red func={() => removeOption(option.index)} />
              )}
            </div>
          ))}
      {data.variant === "boolean" &&
        options
          .sort((a, b) => (a.index > b.index ? 1 : -1))
          .map((option, index) => (
            <div className="options-container" key={option.index}>
              {options.length === 1 ? (
                <Btn green func={addOption} />
              ) : index === options.length - 1 ? (
                <Btn green func={addOption} />
              ) : (
                <Btn red func={() => removeOption(option.index)} />
              )}
            </div>
          ))}
      {data.variant === "select" &&
        options
          .sort((a, b) => (a.index > b.index ? 1 : -1))
          .map((option, index) => (
            <div className="options-container" key={option.index}>
              <input
                className={styles.formField}
                type="text"
                placeholder="Enter select option"
                onChange={(e) => handleOptionChange(e, option.index)}
                defaultValue={option.value}
              />
              {options.length === 1 ? (
                <Btn green func={addOption} />
              ) : index === options.length - 1 ? (
                <Btn green func={addOption} />
              ) : (
                <Btn red func={() => removeOption(option.index)} />
              )}
            </div>
          ))}

      <div>
        {data.type &&
          DEFAULT_OPTION_VARIANTS[data.variant].settings.map((setting, index) => (
            <FormFieldBtn
              key={index}
              name={setting}
              func={toggleSetActive}
              active={data[setting] === true}
            />
          ))}
      </div>
      <HorizontalLine />
    </div>
  );
}
