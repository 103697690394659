import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
// import "firebase/functions";
// import "firebase/messaging";
import firebaseConfig from "../config/firebaseConfig";

class BackendService {
  app: firebase.app.App;
  constructor() {
    this.app = firebase.initializeApp(firebaseConfig, "formula-e5e1b");
  }

  async addDocumentToCollection(
    collection: string,
    data: firebase.firestore.DocumentData
  ): Promise<string> {
    const ref: firebase.firestore.CollectionReference = this.app.firestore().collection(collection);
    const response: firebase.firestore.DocumentReference = await ref.add(data);
    return response.id;
  }

  async setDocument(id: string, collection: string, data: firebase.firestore.DocumentData) {
    const ref: firebase.firestore.CollectionReference = this.app.firestore().collection(collection);
    await ref.doc(id).set(data);
  }

  addDocumentToCollectionWithCloudFunction(
    collection: string,
    payload: object
  ): firebase.functions.HttpsCallable {
    const data: object = {
      collection,
      payload,
    };
    const response: firebase.functions.HttpsCallable = this.app
      .functions()
      .httpsCallable("addToCollectionWithCloudFunction", data);
    return response;
  }

  async getCollection(collection: string): Promise<firebase.firestore.QuerySnapshot> {
    const ref: firebase.firestore.CollectionReference = this.app.firestore().collection(collection);
    const response: firebase.firestore.QuerySnapshot = await ref.get();
    return response;
  }

  async getDocument(collection: string, doc: string): Promise<firebase.firestore.DocumentSnapshot> {
    const ref: firebase.firestore.DocumentReference = this.app
      .firestore()
      .collection(collection)
      .doc(doc);
    const response: firebase.firestore.DocumentSnapshot = await ref.get();
    return response;
  }

  async getDocumentsFromCollectionWhere(
    collection: string,
    key: string,
    value: any
  ): Promise<firebase.firestore.QuerySnapshot> {
    const ref: firebase.firestore.Query<firebase.firestore.DocumentData> = this.app
      .firestore()
      .collection(collection)
      .where(key, "==", value);
    const response = await ref.get();
    return response;
  }

  async updateDocument(
    collection: string,
    id: string,
    data: firebase.firestore.UpdateData
  ): Promise<void> {
    const ref: firebase.firestore.DocumentReference = this.app
      .firestore()
      .collection(collection)
      .doc(id);
    const response: void = await ref.update(data);
    return response;
  }

  async updateValueInDocument(collection: string, id: string, field: string, data: any) {
    const ref: firebase.firestore.DocumentReference = this.app
      .firestore()
      .collection(collection)
      .doc(id);
    const response: void = await ref.update({
      [field]: data,
    });
    return response;
  }

  async deleteValueInDocument(collection: string, id: string, field: string, data: any) {
    const ref = this.app.firestore().collection(collection).doc(id);
    const response = ref.set(
      {
        [field]: {
          [data]: firebase.firestore.FieldValue.delete(),
        },
      },
      { merge: true }
    );
    return response;
  }

  async pushValueToArrayInDocument(collection: string, id: string, field: string, data: any) {
    const ref = this.app.firestore().collection(collection).doc(id);
    const response = await ref.update({
      [field]: firebase.firestore.FieldValue.arrayUnion(data),
    });
    return response;
  }

  async loginWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return this.app.auth().signInWithEmailAndPassword(email, password);
  }

  attachAuthStateChangeHandler(callback: any): void {
    this.app.auth().onAuthStateChanged(callback);
  }

  async signOut(): Promise<void> {
    return await this.app.auth().signOut();
  }

  async saveFileToStorage(file: any, path: string) {
    const storage_ref = this.app.storage().ref(path);
    await storage_ref.put(file);
    const meta = await storage_ref.getMetadata();
    const url = await storage_ref.getDownloadURL();
    return {
      meta,
      url,
    };
  }
}

export let backendService: BackendService = new BackendService();
