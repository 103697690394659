import { Fragment, useContext, useEffect, useState } from "react";
import Btn from "../../components/Btn";
import BtnWide from "../../components/BtnWide";
import { Redirect } from "react-router-dom";
import FormField from "../../components/FormField";
import { backendService } from "../../services/backend";
import { AuthContext } from "../../components/AuthProvider";
// import { backendService } from "../../services/backend";
import styles from "../../components/FormPage/FormPage.module.css";
import HorizontalLine from "../../components/HorizontalLine";
import Loading from "../../components/Loading";
import BtnSmall from "../../components/BtnSmall";
import FormFieldBtn from "../../components/FormFieldBtn";
export default function NewHook() {
  const [dbDataForms, setDbDataForms] = useState([]);
  const [dbDataCollections, setDbDataCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { isAuth } = useContext(AuthContext);
  const [selectedForm, setSelectedForm] = useState("");
  const [selectedCollection, setSelectedCollection] = useState("");
  const [formKeyToInspect, setFormKeyToInspect] = useState("");
  const [formKeyToInspectCondition, setFormKeyToInspectCondition] = useState("");
  const [formKeyToCollect, setFormKeyToCollect] = useState("");
  const [formSlug, setFormSlug] = useState("");
  useEffect(() => {
    let _data = [];
    (async () => {
      setIsLoading(true);
      let req = await backendService
        .getDocumentsFromCollectionWhere("forms", "userId", isAuth.uid)
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            _data.push({ id: doc.id, ...doc.data() });
          });
        })
        .then(() => {
          setDbDataForms(_data);
        })
        .then(() => {
          setIsLoading(false);
        });
    })();
  }, [isAuth.uid]);

  useEffect(() => {
    let _data = [];
    (async () => {
      setIsLoading(true);
      let req = await backendService
        .getDocumentsFromCollectionWhere("collections", "userId", isAuth.uid)
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            _data.push({ id: doc.id, ...doc.data() });
          });
        })
        .then(() => {
          setDbDataCollections(_data);
        })
        .then(() => {
          setIsLoading(false);
        });
    })();
  }, [isAuth.uid]);

  const getForm = async (slug) => {
    let _data = [];

    setIsLoading(true);
    let req = await backendService
      .getDocumentsFromCollectionWhere("forms", "slug", slug)
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          _data.push({ id: doc.id, ...doc.data() });
        });
      })
      .then(() => {
        setSelectedForm(..._data);
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const getCollection = async (slug) => {
    let _data = [];

    setIsLoading(true);
    let req = await backendService
      .getDocumentsFromCollectionWhere("collections", "slug", slug)
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          _data.push({ id: doc.id, ...doc.data() });
        });
      })
      .then(() => {
        setSelectedCollection(..._data);
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const prepareDataForSubmission = () => {
    let obj = {
      userId: isAuth.uid,
      // title: formTitle,
      slug: formSlug.toLowerCase().replace(/\s+/g, "-"),
      createdAt: new Date().getTime(),
      expiresAt: "",
      archived: false,
      data: {
        formId: selectedForm.id,
        collectionId: selectedCollection.id,
        formKeyToInspect: formKeyToInspect,
        formKeyToInspectCondition: formKeyToInspectCondition,
        formKeyToCollect: formKeyToCollect,
      },
    };
    return obj;
  };

  const handleSubmit = async () => {
    try {
      let _formData = prepareDataForSubmission();
      await backendService
        .addDocumentToCollection("hooks", _formData)
        .then((response) =>
          backendService.pushValueToArrayInDocument("forms", selectedForm.id, "hookIds", response)
        )
        .then(() => setFormSubmitted(true));
    } catch (error) {
      await backendService.addDocumentToCollection("logs", error);
    }
  };

  if (formSubmitted) {
    return <Redirect to="/dashboard/hooks" />;
  }
  return (
    <div className="page-container">
      <h1>Create new hook</h1>

      {isLoading ? <Loading /> : ""}

      <div className="flex-column">
        <label>Form Slug</label>
        <input
          placeholder="An easy way to refer to your hook"
          className={styles.formFieldStretch}
          type="text"
          onChange={(e) => setFormSlug(e.target.value)}
        />
      </div>

      <div>
        <h4>Select collection</h4>
        {dbDataCollections.map((form) => (
          <FormFieldBtn
            func={() => getCollection(form.slug)}
            name={form.slug}
            key={form.id}
            active={selectedCollection.slug === form.slug}
          />
        ))}
      </div>

      <div>
        <h4>Select form</h4>
        {dbDataForms.map((form) => (
          <FormFieldBtn
            func={() => getForm(form.slug)}
            name={form.slug}
            key={form.id}
            active={selectedForm.slug === form.slug}
          />
        ))}
      </div>
      <div>
        <h4>Select form field to hook collection to</h4>
        {selectedForm &&
          Object.keys(selectedForm.data).map((formIndex, index) => (
            <FormFieldBtn
              func={() => setFormKeyToInspect(formIndex)}
              name={`${formIndex}: ${selectedForm.data[formIndex].label}`}
              key={`formData_${formIndex}`}
              active={formIndex === formKeyToInspect}
            />
          ))}
      </div>

      <div>
        <h4>Conditions to collect information (leave blank if not required)</h4>
        <p>
          {formKeyToInspect && (
            <Fragment>
              <span>if {formKeyToInspect} === </span>
              <input
                className="form-field"
                onChange={(e) => setFormKeyToInspectCondition(e.target.value)}
              />
            </Fragment>
          )}
        </p>
      </div>

      <div>
        <h4>Select form field to hook information from </h4>
        {formKeyToInspect &&
          Object.keys(selectedForm.data).map((formIndex, index) => (
            <FormFieldBtn
              func={() => setFormKeyToCollect(formIndex)}
              name={`${formIndex}: ${selectedForm.data[formIndex].label}`}
              key={`formData_${formIndex}`}
              active={formIndex === formKeyToCollect}
            />
          ))}
      </div>

      <BtnWide func={handleSubmit} name="Save hook" />
    </div>
  );
}
