import Dashboard from "../pages/Authenticated/Dashboard";
import Login from "../pages/Login";
// import Register from "../pages/Register";
import Index from "../pages/Index";
import { IRoute } from "../types";
import NewForm from "../pages/Authenticated/NewForm";
import Form from "../pages/Form[id]";
import FormData from "../pages/Authenticated/FormData[id]";
import Collections from "../pages/Authenticated/Collections";
import NewCollection from "../pages/Authenticated/NewCollection";
import CollectionData from "../pages/Authenticated/CollectionData[id]";
import FormEdit from "../pages/Authenticated/FormEdit[id]";
import Forms from "../pages/Authenticated/Forms";
import Hooks from "../pages/Authenticated/Hooks";
import NewHook from "../pages/Authenticated/NewHook";
import Keys from "../pages/Authenticated/Keys";
import NewKey from "../pages/Authenticated/NewKey";
import KeyEdit from "../pages/Authenticated/KeyEdit[id]";
// import OrbitDashboard from "../pages/Authenticated/Orbit/OrbitDashboard";
// import OrbitSetup from "../pages/Authenticated/Orbit/OrbitSetup";
// import OrbitStats from "../pages/Authenticated/Orbit/OrbitStats";

export const privateRoutes = [
  { path: "/dashboard", component: Dashboard, routeName: "dashboard" },
  { path: "/dashboard/forms", component: Forms, routeName: "forms" },
  { path: "/dashboard/form/new", component: NewForm, routeName: "newForm" },
  { path: "/dashboard/d/:id", component: FormData, routeName: "formData" },
  { path: "/dashboard/f/:id", component: FormEdit, routeName: "formEdit" },
  { path: "/dashboard/collections", component: Collections, routeName: "collections" },
  { path: "/dashboard/collection/new", component: NewCollection, routeName: "newCollection" },
  { path: "/dashboard/c/:id", component: CollectionData, routeName: "collectionData" },
  { path: "/dashboard/hooks", component: Hooks, routeName: "collections" },
  { path: "/dashboard/hook/new", component: NewHook, routeName: "newHook" },
  { path: "/dashboard/keys", component: Keys, routeName: "keys" },
  { path: "/dashboard/key/new", component: NewKey, routeName: "newKey" },
  { path: "/dashboard/k/:id", component: KeyEdit, routeName: "keyEdit" },
  // { path: "/dashboard/orbit", component: OrbitDashboard, routeName: "orbitDashboard" },
  // { path: "/dashboard/orbit/setup", component: OrbitSetup, routeName: "orbitSetup" },
  // { path: "/dashboard/orbit/stats", component: OrbitStats, routeName: "orbitStats" },
] as IRoute[];

export const publicRoutes = [
  { path: "/", component: Index, routeName: "index" },
  { path: "/f/:id", component: Form, routeName: "form" },
  { path: "/login", component: Login, routeName: "login" },
  // { path: "/register", component: Register, routeName: "register" },
] as IRoute[];
