import { IFirebaseConfig } from "../types";

const firebaseConfig = {
  apiKey: "AIzaSyBDn2N9NmPxzv-dTDcsdect1FBoadfvY0Y",
  authDomain: "formula-e5e1b.firebaseapp.com",
  projectId: "formula-e5e1b",
  storageBucket: "formula-e5e1b.appspot.com",
  messagingSenderId: "1086898797664",
  appId: "1:1086898797664:web:9577d17a40388cc9201705",
  measurementId: "G-K6MT76DEWF",
} as IFirebaseConfig;

export default firebaseConfig;
