// import { useState } from "react";
import { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../components/AuthProvider";
import BtnSmall from "../../components/BtnSmall";
import FormFieldBtn from "../../components/FormFieldBtn";
import HorizontalLine from "../../components/HorizontalLine";
import { isValidEmail } from "../../functions/isValidEmail";
import { backendService } from "../../services/backend";

export default function FormData() {
  const { isAuth } = useContext(AuthContext);
  const params = useParams();
  const [dbSubmissionData, setDbSubmissionData] = useState("");
  const [loading, setLoading] = useState(true);
  const [dbFormData, setDbFormData] = useState("");
  const [formDataPreparedForUI, setFormDataPreparedForUI] = useState("");
  const [submissionDataPreparedForUI, setSubmissionDataPreparedForUI] = useState("");
  const [collectionData, setCollectionData] = useState();
  const [showArchived, setShowArchived] = useState(false);

  useEffect(() => {
    let _data = [];
    (async () => {
      setLoading(true);
      let req = await backendService
        .getDocumentsFromCollectionWhere("collections", "userId", isAuth.uid)
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            _data.push({ id: doc.id, ...doc.data() });
          });
        })
        .then(() => {
          setCollectionData(_data);
        })
        .then(() => {
          setLoading(false);
        });
    })();
  }, [isAuth.uid]);

  useEffect(() => {
    if (params.id && !dbFormData) {
      (async () => {
        await backendService
          .getDocument("forms", params.id)
          .then((doc) => setDbFormData({ id: doc.id, ...doc.data() })) // possibly wrap this inside an array if it makes things easier
          .then(() => setLoading(false));
      })();
    }
  }, [params.id, dbFormData]);

  useEffect(() => {
    if (params.id && !dbSubmissionData) {
      (async () => {
        let _dbSubmissionData = [];
        await backendService
          .getDocumentsFromCollectionWhere("submissions", "formId", params.id)
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              _dbSubmissionData.push({ id: doc.id, ...doc.data() });
            });
          })
          .then(() => setDbSubmissionData(_dbSubmissionData))
          .then(() => setLoading(false));
      })();
    }
  }, [params.id, dbSubmissionData]);

  useEffect(() => {
    if (dbSubmissionData.length && !submissionDataPreparedForUI) {
      let _dbSubmissionData = dbSubmissionData.map((_d) => ({
        id: _d.id,
        createdAt: _d.createdAt,
        data: Object.entries(_d.data),
        archived: _d.archived || false,
      }));
      setSubmissionDataPreparedForUI(_dbSubmissionData);
    }
  }, [dbSubmissionData, submissionDataPreparedForUI]);

  useEffect(() => {
    if (dbFormData.id && !formDataPreparedForUI) {
      let data = [
        {
          keys: Object.keys(dbFormData.data),
          labels: Object.values(dbFormData.data).map((_v) => _v.label),
        },
      ];
      let _a = data[0].keys.map((val, index) => ({
        key: val,
        label: data[0].labels[index],
      }));

      let _title = dbFormData.title;
      let _createdAt = dbFormData.createdAt;
      setFormDataPreparedForUI({ createdAt: _createdAt, data: _a, title: _title });
    }
  }, [dbFormData, formDataPreparedForUI]);

  // this is purely a visual thing to represent
  // what the DB looks like after the archive/unarchive update is made
  // in order to avoid another DB call
  const updateDataInView = (idToUpdate, newArchiveValue) => {
    let _dbSubmissionData = dbSubmissionData.map((_d) => ({
      id: _d.id,
      createdAt: _d.createdAt,
      data: Object.entries(_d.data),
      archived: _d.archived || false,
    }));

    _dbSubmissionData.forEach((_entry) => {
      if (_entry.id === idToUpdate) {
        _entry.archived = newArchiveValue;
      }
    });
    setSubmissionDataPreparedForUI(_dbSubmissionData);
  };

  const archiveDocument = async (documentId) => {
    await backendService.updateValueInDocument("submissions", documentId, "archived", true);
    // .then(() => updateDataInView(documentId, true));
  };

  const unarchiveDocument = async (documentId) => {
    await backendService.updateValueInDocument("submissions", documentId, "archived", false);
    // .then(() => updateDataInView(documentId, false));
  };

  const handleRemoveFromCollection = async (collectionId, dataId, value) => {
    const _existingCollectionData = collectionData.filter(
      (collection) => collection.id === collectionId
    )?.[0]?.data;

    let _updatedCollectionData = _existingCollectionData;
    _updatedCollectionData[dataId] = value;

    await backendService
      .deleteValueInDocument("collections", collectionId, "data", dataId)
      .then((response) => {
        // update the data in the UI instead of fetching from the DB again
        const _collectionData = collectionData.filter(
          (collection) => collection.id === collectionId
        )[0];

        const _restCollectionData = collectionData.filter(
          (collection) => collection.id !== collectionId
        );

        delete _collectionData.data[dataId];

        setCollectionData([..._restCollectionData, _collectionData]);
      });
  };

  const handleAddToCollection = async (collectionId, dataId, value) => {
    const _existingCollectionData = collectionData.filter(
      (collection) => collection.id === collectionId
    )?.[0]?.data;

    let _updatedCollectionData = _existingCollectionData;
    _updatedCollectionData[dataId] = value;

    await backendService
      .updateValueInDocument("collections", collectionId, "data", _updatedCollectionData)
      .then(() => {
        // update the data in the UI instead of fetching from the DB again
        const _collectionData = collectionData.filter(
          (collection) => collection.id === collectionId
        )[0];

        const _restCollectionData = collectionData.filter(
          (collection) => collection.id !== collectionId
        );

        _collectionData.data[dataId] = value;

        setCollectionData([_collectionData, ..._restCollectionData]);
      });
  };

  const handleAddEntireEntryToCollection = async (collectionId, dataId, value) => {
    let _updatedCollectionData = collectionData.filter(
      (collection) => collection.id === collectionId
    )?.[0]?.data;
    _updatedCollectionData[dataId] = Object.fromEntries(value);

    await backendService.updateValueInDocument(
      "collections",
      collectionId,
      "data",
      _updatedCollectionData
    );
    // .then(() => {
    //   // update the data in the UI instead of fetching from the DB again
    //   const _collectionData = collectionData.filter(
    //     (collection) => collection.id === collectionId
    //   )[0];

    //   const _restCollectionData = collectionData.filter(
    //     (collection) => collection.id !== collectionId
    //   );

    //   _collectionData.data[dataId] = value;

    //   setCollectionData([_collectionData, ..._restCollectionData]);
    // });
  };

  if (loading) {
    return (
      <div className="page-container">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <div className="page-container">
      {formDataPreparedForUI.title && <h1>{formDataPreparedForUI.title}</h1>}

      <h3>Form Questions</h3>
      {/* <p>
        {" "}
        Form created:{" "}
        {formDataPreparedForUI && new Date(formDataPreparedForUI.createdAt).toString()}
      </p> */}
      <table>
        <thead>
          <tr>
            {formDataPreparedForUI &&
              formDataPreparedForUI.data.map((data, index) => (
                <th key={`formData_${index}_${data.key}`}>
                  {" "}
                  {data.key}: {data.label}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
          </tr>
        </tbody>
      </table>
      <br />
      <h3>Form Submissions</h3>
      {submissionDataPreparedForUI && <p>Total: {submissionDataPreparedForUI.length}</p>}
      {submissionDataPreparedForUI && (
        <p>
          Unarchived:{" "}
          {submissionDataPreparedForUI.filter((_a) => _a.archived === showArchived).length}
        </p>
      )}
      {showArchived ? (
        <BtnSmall func={() => setShowArchived(false)} name="Show unarchived" />
      ) : (
        <BtnSmall func={() => setShowArchived(true)} name="Show archived" />
      )}
      {submissionDataPreparedForUI &&
        submissionDataPreparedForUI
          .filter((_a) => _a.archived === showArchived)
          .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
          .map((_data) => (
            <div key={_data.id}>
              <HorizontalLine />
              <br />
              {_data.archived ? (
                <FormFieldBtn func={() => unarchiveDocument(_data.id)} name="Unarchive" active />
              ) : (
                <FormFieldBtn func={() => archiveDocument(_data.id)} name="Archive" active />
              )}
              <br />
              <br />
              <em>{new Date(_data.createdAt).toString()}</em>
              {_data.data.map((v, _index) => (
                <Fragment key={`${_index}_${v[0]}`}>
                  <p>
                    {v[0]}: {v[1].toString()}
                  </p>
                  {/* {isValidEmail(v[1]) && */}
                  {collectionData
                    .filter((collection) => collection.archived !== true)
                    .map((collection) => (
                      <div key={collection.id}>
                        {Object.keys(collection.data).includes(_data.id) ? (
                          <BtnSmall
                            func={() => handleRemoveFromCollection(collection.id, _data.id, v[1])}
                            name={`Remove ${v[0]} from ${collection.title}`}
                          />
                        ) : (
                          <BtnSmall
                            func={() => handleAddToCollection(collection.id, _data.id, v[1])}
                            name={`Add ${v[0]} to ${collection.title}`}
                          />
                        )}
                      </div>
                    ))}
                </Fragment>
              ))}
              {/* {collectionData
                .filter((collection) => collection.archived !== true)
                .map((collection) => (
                  <div key={collection.id}>
                    {Object.keys(collection.data).includes(_data.id) ? (
                      <BtnSmall
                        func={() =>
                          handleAddEntireEntryToCollection(collection.id, _data.id, _data.data)
                        }
                        name={`Remove entire entry from ${collection.title}`}
                      />
                    ) : (
                      <BtnSmall
                        func={() =>
                          handleAddEntireEntryToCollection(collection.id, _data.id, _data.data)
                        }
                        name={`Add entire entry to ${collection.title}`}
                      />
                    )}
                  </div>
                ))} */}
            </div>
          ))}
    </div>
  );
}
