import { useState, useEffect, useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../components/AuthProvider";
import Btn from "../../components/Btn";
import BtnSmall from "../../components/BtnSmall";
import HorizontalLine from "../../components/HorizontalLine";
import Loading from "../../components/Loading";
import { backendService } from "../../services/backend";

export default function Hooks() {
  const { isAuth } = useContext(AuthContext);
  const [dbData, setDbData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const signOut = () => {
    backendService.signOut();
  };

  useEffect(() => {
    let _data = [];
    (async () => {
      setIsLoading(true);
      let req = await backendService
        .getDocumentsFromCollectionWhere("hooks", "userId", isAuth.uid)
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            _data.push({ id: doc.id, ...doc.data() });
          });
        })
        .then(() => {
          setDbData(_data);
        })
        .then(() => {
          setIsLoading(false);
        });
    })();
  }, [isAuth.uid]);

  return (
    <div className="page-container">
      <div className="options-container">
        <h1>Hooks</h1>
        <Link to="/dashboard">
          <h3>Dashboard</h3>
        </Link>
      </div>
      <Link to="/dashboard/hook/new" className="options-container">
        <h3>Create new hook</h3>
        <Btn green />
      </Link>
      <HorizontalLine />
      <h3>Existing hooks</h3>
      {isLoading ? (
        <Loading />
      ) : (
        dbData.length > 0 &&
        dbData.map((data) => (
          <Fragment key={data.id}>
            <ExistingHookLink data={data} />

            {/* <Link to={`/dashboard/d/${data.id}`}>
              <BtnSmall name="View submission data" />
            </Link> */}

            <HorizontalLine />
          </Fragment>
        ))
      )}
    </div>
  );
}

function ExistingHookLink({ data }) {
  return (
    <div>
      <h4>Hook: {data.slug}</h4>
      {/* 
      <Link to={`/dashboard/f/${data.id}`} className="options-container">
        <p>Edit hook</p>
        <Btn orange />
      </Link> */}
    </div>
  );
}
