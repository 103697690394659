import styles from "./Btn.module.css";

export default function Btn(props) {
  if (props.green) {
    const img = require("../../assets/plus-symbol.png").default;
    return (
      <button
        onClick={props.func}
        className={styles.btn}
        style={{
          backgroundColor: "var(--green)",
          backgroundImage: `url(${img})`,
        }}></button>
    );
  }
  if (props.red) {
    const img = require("../../assets/minus-symbol.png").default;
    return (
      <button
        onClick={props.func}
        className={styles.btn}
        style={{
          backgroundColor: "var(--red)",
          backgroundImage: `url(${img})`,
        }}></button>
    );
  }
  if (props.orange) {
    const img = require("../../assets/minus-symbol.png").default;
    return (
      <button
        onClick={props.func}
        className={styles.btn}
        style={{
          backgroundColor: "var(--orange)",
          backgroundImage: `url(${img})`,
        }}></button>
    );
  }

  if (props.hook) {
    return (
      <button onClick={props.func} className={styles.btn} style={{}}>
        {" "}
        ⚓️
      </button>
    );
  }
  throw new Error("Btn requires a color prop. Pass either red, green, orange, or hook");
}
