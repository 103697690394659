import { useState, useEffect, createContext } from "react";
import { backendService } from "../services/backend";
export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(null);
  useEffect(() => {
    backendService.attachAuthStateChangeHandler((user) => {
      setIsAuth(user);
    });
  }, [isAuth]);
  return <AuthContext.Provider value={{ isAuth }}>{children}</AuthContext.Provider>;
};
