import { useContext, useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { backendService } from "../../services/backend";
import styles from "../../components/FormPage/FormPage.module.css";
import BtnWide from "../../components/BtnWide";
import Loading from "../../components/Loading";
import { AuthContext } from "../../components/AuthProvider";

export default function KeyEdit() {
  const params = useParams();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formUrl, setFormUrl] = useState("");
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [isFormError, setIsFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("");
  const [activeApiKey, setActiveApiKey] = useState("");
  const [activeDocument, setActiveDocument] = useState("");
  const { isAuth } = useContext(AuthContext);

  useEffect(() => {
    let _data = [];
    (async () => {
      setIsLoading(true);
      await backendService
        .getDocumentsFromCollectionWhere("keys", "userId", isAuth.uid)
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) =>
            setActiveKey(...doc.data().keyIds.filter((id) => id === params.id))
          );
        })
        .then(() => {
          setIsLoading(false);
        });
    })();
  }, [isAuth.uid, params.id]);

  useEffect(() => {
    if (activeKey) {
      let _data = [];
      (async () => {
        setIsLoading(true);
        await backendService
          .getDocument("api", activeKey)
          .then((doc) => setActiveApiKey({ id: doc.id, ...doc.data() }))
          .then(() => setIsLoading(false));
      })();
    }
  }, [activeKey, isAuth.uid, params.id]);

  // console.log({ activeApiKey, activeDocument, activeKey });

  useEffect(() => {
    if (activeApiKey) {
      let _data = [];
      (async () => {
        setIsLoading(true);
        await backendService
          .getDocument("forms", activeApiKey.formId)
          .then((doc) => setActiveDocument({ id: doc.id, ...doc.data() }))
          .then(() => setIsLoading(false));
      })();
    }
  }, [activeApiKey, isAuth.uid, params.id]);

  const handleSubmit = async () => {
    if (formUrl.length > 0) {
      try {
        await backendService
          .updateValueInDocument("api", activeApiKey.id, "url", formUrl)
          .then(() => setFormSubmitted(true));
      } catch (error) {
        await backendService.addDocumentToCollection("logs", error);
        setIsFormError(true);
        setFormErrorMessage("There was an error updating your form. Please try again later");
      }
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (formSubmitted) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <div className="page-container">
      <div className="page-container-inner">
        <h1>Edit key</h1>

        <div className="flex-column">
          <label>Key URL</label>
          <input
            className={styles.formFieldStretch}
            type="text"
            defaultValue={activeApiKey.url}
            onChange={(e) => setFormUrl(e.target.value)}
          />
        </div>
        <BtnWide func={handleSubmit} name="Save updates" />
        {isFormError && <p>{formErrorMessage}</p>}
      </div>
    </div>
  );
}
