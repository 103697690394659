import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { publicRoutes, privateRoutes } from "./routes";
import PrivateRoute from "./components/PrivateRouteComponent";
import { AuthProvider } from "./components/AuthProvider";

const App = () => {
  return (
    <Router>
      <Switch>
        <AuthProvider>
          {privateRoutes.map((route, index) => (
            <PrivateRoute
              path={route.path}
              key={`${route.path}_${index}`}
              component={route.component}
              exact
            />
          ))}
          {publicRoutes.map((route, index) => (
            <Route
              path={route.path}
              key={`${route.path}_${index}`}
              component={route.component}
              exact
            />
          ))}
          {/* <Redirect to="/" /> */}
        </AuthProvider>
      </Switch>
    </Router>
  );
};

export default App;
