import { useContext, useState } from "react";
import Btn from "../../components/Btn";
import BtnWide from "../../components/BtnWide";
import { Redirect } from "react-router-dom";
import FormField from "../../components/FormField";
import { backendService } from "../../services/backend";
import { AuthContext } from "../../components/AuthProvider";
// import { backendService } from "../../services/backend";
import styles from "../../components/FormPage/FormPage.module.css";
import HorizontalLine from "../../components/HorizontalLine";
export default function NewCollection() {
  const [lastIndex, setLastIndex] = useState(100);
  const [formData, setFormData] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { isAuth } = useContext(AuthContext);
  const [formTitle, setFormTitle] = useState("");
  const [formSlug, setFormSlug] = useState("");

  const handleFormUpdate = (updatedData) => {
    const formDataIndex = formData.findIndex((element) => element.index === updatedData.index);
    let newArray = [...formData];
    newArray[formDataIndex] = {
      ...updatedData,
    };
    setFormData(newArray);
  };

  const addFormField = () => {
    let newLastIndex = lastIndex + 1;
    let newCollectionField = { index: newLastIndex, variant: "input", type: "text", label: "" };
    let newArray = [...formData, newCollectionField];
    setLastIndex(newLastIndex);
    setFormData(newArray);
  };

  const removeFormField = (index) => {
    setFormData(formData.filter((data) => data.index !== index));
  };

  const prepareDataForSubmission = () => {
    let obj = {
      userId: isAuth.uid,
      title: formTitle,
      slug: formSlug
        ? formSlug.toLowerCase().replace(/\s+/g, "-")
        : formTitle.toLowerCase().replace(/\s+/g, "-"),
      createdAt: new Date().getTime(),
      expiresAt: "",
      archived: false,
      data: {},
    };
    return obj;
  };

  const handleSubmit = async () => {
    if (formTitle.length > 1) {
      try {
        let _formData = prepareDataForSubmission();
        let response = await backendService.addDocumentToCollection("collections", _formData);
        if (response) {
          setFormSubmitted(true);
        }
      } catch (error) {
        await backendService.addDocumentToCollection("logs", error);
      }
    }
  };

  if (formSubmitted) {
    return <Redirect to="/dashboard/collections" />;
  }
  return (
    <div className="page-container">
      <h1>Create new form</h1>

      <div className="flex-column">
        <label>Collection name</label>
        <input
          className={styles.formFieldStretch}
          type="text"
          onChange={(e) => setFormTitle(e.target.value)}
        />
      </div>

      <div className="flex-column">
        <label>Collection Slug</label>
        <input
          placeholder="An easy way to refer to your collection - used for creating form hooks"
          className={styles.formFieldStretch}
          type="text"
          onChange={(e) => setFormSlug(e.target.value)}
        />
      </div>

      <BtnWide func={handleSubmit} name="Save collection" />
    </div>
  );
}
