import { useState, useEffect, useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../components/AuthProvider";
import Btn from "../../components/Btn";
import BtnSmall from "../../components/BtnSmall";
import FormFieldBtn from "../../components/FormFieldBtn";
import HorizontalLine from "../../components/HorizontalLine";
import Loading from "../../components/Loading";
import { backendService } from "../../services/backend";

export default function Collections() {
  const { isAuth } = useContext(AuthContext);
  let [dbData, setDbData] = useState([]);
  let [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let _data = [];
    (async () => {
      setIsLoading(true);
      let req = await backendService
        .getDocumentsFromCollectionWhere("collections", "userId", isAuth.uid)
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            _data.push({ id: doc.id, ...doc.data() });
          });
        })
        .then(() => {
          setDbData(_data);
        })
        .then(() => {
          setIsLoading(false);
        });
    })();
  }, [isAuth.uid]);

  const handleUpdateDataInView = async (collectionId, newVal) => {
    // update the data in the UI instead of fetching from the DB again
    let _dataToUpdate = dbData.filter((collection) => collection.id === collectionId)[0];
    const _restData = dbData.filter((collection) => collection.id !== collectionId);
    _dataToUpdate.archived = newVal;
    setDbData([..._restData, _dataToUpdate]);
  };

  const handleArchiveCollection = async (collectionId, newVal) => {
    await backendService
      .updateValueInDocument("collections", collectionId, "archived", newVal)
      .then(handleUpdateDataInView(collectionId, newVal));
  };

  return (
    <div className="page-container">
      <div className="options-container">
        <h1>Collections</h1>
        <Link to="/dashboard">
          <h3>Dashboard</h3>
        </Link>
      </div>
      <Link to="/dashboard/collection/new" className="options-container">
        <h3>Create new collection</h3>
        <Btn green />
      </Link>
      <HorizontalLine />
      <h3>Existing collections</h3>
      {isLoading ? (
        <Loading />
      ) : (
        dbData.length > 0 &&
        dbData.map((data) => (
          <Fragment key={data.id}>
            <ExistingFormLink data={data} handleArchiveCollection={handleArchiveCollection} />
            <p>
              Slug: <em>{data.slug}</em>
            </p>
            <HorizontalLine />
          </Fragment>
        ))
      )}
    </div>
  );
}

function ExistingFormLink({ data, handleArchiveCollection }) {
  return (
    <div className="options-container">
      <h4>
        {data.title}: <Link to={`/dashboard/c/${data.id}`}>view data</Link>
      </h4>
      {data.archived ? (
        <FormFieldBtn name="unarchive" func={() => handleArchiveCollection(data.id, false)} />
      ) : (
        <FormFieldBtn name="archive" func={() => handleArchiveCollection(data.id, true)} />
      )}
    </div>
  );
}
