// import styles from "./FormFieldBtn.module.css";
import styled from "styled-components";

// TODO set up conditional styling
const StyledButton = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid var(--color-option);
  background: var(--color-option);
  border-radius: 5px;
  height: 30px;
  min-width: 110px;
  font-family: var(--font-header);
  margin-right: 10px;
  cursor: pointer;
  background: ${(props) => (props.active ? "var(--color-option);" : "transparent")};
`;

export default function FormFieldBtn(props) {
  return (
    <StyledButton onClick={() => props.func(props.name)} active={props.active}>
      {props.name}
    </StyledButton>
  );
}
