export const DEFAULT_OPTIONS = ["input", "textarea", "select", "checkbox", "boolean"];
export const DEFAULT_OPTION_VARIANTS = {
  input: {
    variant: "input",
    label: "",
    placeholder: "",
    type: [
      "text",
      "date",
      // "datetime-local",
      "email",
      "month",
      "number",
      // "password",
      "tel",
      "time",
      "url",
      "week",
    ],
    settings: ["required", "autocomplete"],
  },
  textarea: {
    variant: "textarea",
    label: "",
    placeholder: "",
    type: [],
    settings: ["required"],
  },
  select: {
    variant: "select",
    label: "",
    placeholder: "",
    type: [],
    settings: ["required"],
  },
  checkbox: {
    variant: "checkbox",
    label: "",
    placeholder: "",
    type: [],
    settings: ["required"],
  },
  boolean: {
    variant: "boolean",
    label: "",
    placeholder: "",
    type: [],
    settings: ["required"],
  },
};
