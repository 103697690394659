import { useState, useEffect, useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../components/AuthProvider";
import Btn from "../../components/Btn";
import BtnSmall from "../../components/BtnSmall";
import HorizontalLine from "../../components/HorizontalLine";
import Loading from "../../components/Loading";
import { backendService } from "../../services/backend";

export default function Forms() {
  const { isAuth } = useContext(AuthContext);
  const [dbData, setDbData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const signOut = () => {
    backendService.signOut();
  };

  useEffect(() => {
    let _data = [];
    (async () => {
      setIsLoading(true);
      let req = await backendService
        .getDocumentsFromCollectionWhere("forms", "userId", isAuth.uid)
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            _data.push({ id: doc.id, ...doc.data() });
          });
        })
        .then(() => {
          setDbData(_data);
        })
        .then(() => {
          setIsLoading(false);
        });
    })();
  }, [isAuth.uid]);

  return (
    <div className="page-container">
      <div className="options-container">
        <h1>Forms</h1>
        <Link to="/dashboard">
          <h3>Dashboard</h3>
        </Link>
      </div>
      <Link to="/dashboard/form/new" className="options-container">
        <h3>Create new form</h3>
        <Btn green />
      </Link>
      <HorizontalLine />
      <h3>Existing forms</h3>
      {isLoading ? (
        <Loading />
      ) : (
        dbData.length > 0 &&
        dbData.map((data) => (
          <Fragment key={data.id}>
            <ExistingFormLink data={data} />

            <Link to={`/dashboard/d/${data.id}`}>
              <BtnSmall name="View submission data" />
            </Link>

            <HorizontalLine />
          </Fragment>
        ))
      )}
    </div>
  );
}

function ExistingFormLink({ data }) {
  return (
    <div>
      <h4>Form: {data.title}</h4>
      <p>Slug: {data.slug}</p>
      <p>
        Link: <Link to={`/f/${data.id}`}>formulatools.co/f/{data.id}</Link>
      </p>

      <Link to={`/dashboard/f/${data.id}`} className="options-container">
        <p>Edit form</p>
        <Btn orange />
      </Link>
    </div>
  );
}
